import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { client, urlFor } from "../lib/sanity";

const Projects = () => {
  const theme = useTheme();

  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    const response = await client.fetch('*[_type == "projects"]');

    // console.log(response);
    setProjects(response);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div id="projects">
      <Box
        maxWidth={{ sm: 720, md: 1236 }}
        width={1}
        margin="0 auto"
        paddingX={2}
        paddingY={{ xs: 4, sm: 6, md: 8 }}
      >
        <Box marginBottom={4}>
          <Typography
            variant="h2"
            fontWeight={1000}
            align={"center"}
            fontSize={"3.2rem"}
            marginTop="5px"
            data-aos="fade-up"
            gutterBottom
            color={"#f56539"}
          >
            Projects
          </Typography>
          <Typography
            variant="h5"
            color={"#fff"}
            align="center"
            data-aos="fade-up"
            marginTop={4}
            marginBottom={6}
          >
            Here are some of my latest projects :
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {projects.map((item, i) => (
            <Grid key={i} item xs={12}>
              <Box
                display="block"
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-4px)",
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  borderRadius={0}
                  boxShadow={0}
                  display="flex"
                  flexDirection={{
                    xs: "column",
                    md: i % 2 === 0 ? "row-reverse" : "row",
                  }}
                  sx={{ backgroundImage: "none", bgcolor: "transparent" }}
                >
                  <Box
                    sx={{
                      width: { xs: 1, md: "50%" },
                      "& .lazy-load-image-loaded": {
                        height: 1,
                        display: "flex !important",
                      },
                    }}
                  >
                    <Box
                      component={LazyLoadImage}
                      height={1}
                      width={1}
                      src={urlFor(item?.image)}
                      alt="project image"
                      effect="blur"
                      sx={{
                        objectFit: "fit",
                        maxHeight: 320,
                        borderRadius: 2,
                        filter: "none",
                        transition: "opacity, transform ease 0.3s !important",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      paddingX: { xs: 1, sm: 2, md: 4 },
                      paddingY: { xs: 2, sm: 4 },
                      width: { xs: 1, md: "50%" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      marginBottom={1}
                      fontSize={"1.5rem"}
                      sx={{ textTransform: "uppercase" }}
                      color={"#fff"}
                    >
                      {item.name}
                    </Typography>
                    <Typography color="#fff">{item.description}</Typography>
                    <Box marginTop={3} marginBottom={1}>
                      {item.tags.map((tag, i) => (
                        <Chip
                          key={i}
                          label={tag}
                          component="a"
                          href=""
                          clickable
                          size="small"
                          // color="#f56539"
                          sx={{
                            marginBottom: 1,
                            marginRight: 1,
                            color: "#fff",
                            backgroundColor: "#f56539",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#fff",
                              border: "1px solid " + "#f56539",
                            },
                          }}
                        />
                      ))}
                    </Box>
                    <Box marginTop={2} display="flex" justifyContent="flex-end">
                      <Button
                        component="a"
                        href={item.link}
                        target="_blank"
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid " + "#f56539",
                          color: "#f56539",
                          "&:hover": {
                            backgroundColor: "#f56539",
                            color: "#fff",
                            border: "1px solid " + "#fff",
                          },
                        }}
                        // endIcon={
                        //   <Box
                        //     component={"svg"}
                        //     xmlns="http://www.w3.org/2000/svg"
                        //     fill="none"
                        //     viewBox="0 0 24 24"
                        //     stroke="currentColor"
                        //     width={24}
                        //     height={24}
                        //   >
                        //     {/* <path
                        //       strokeLinecap="round"
                        //       strokeLinejoin="round"
                        //       strokeWidth={2}
                        //       d="M17 8l4 4m0 0l-4 4m4-4H3"
                        //     /> */}
                        //   </Box>
                        // }
                      >
                        Source Code
                      </Button>
                    </Box>
                  </CardContent>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
    </div>
  );
};

export default Projects;
