import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { client } from "../lib/sanity";

const Testimonials = () => {
  // const theme = useTheme();
  const [testimonials, setTestimonials] = useState([]);

  const fetchTestimonials = async () => {
    const response = await client.fetch('*[_type == "testimonials"]');

    console.log(response);
    setTestimonials(response);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  return (
    <div id="testimonials">
      <Box
        maxWidth={{ sm: 720, md: 1236 }}
        width={1}
        margin="0 auto"
        paddingX={2}
        paddingY={{ xs: 4, sm: 6, md: 8 }}
      >
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant="h2"
              align="center"
              fontWeight={1000}
              fontSize={"3.2rem"}
              marginTop="5px"
              data-aos="fade-up"
              gutterBottom
              color={"#f56539"}
            >
              Experience
            </Typography>
            <Typography
              variant="h4"
              color={"#fff"}
              align="center"
              data-aos="fade-up"
              marginTop={4}
              marginBottom={6}
            >
              Places where I worked before :
            </Typography>
          </Box>
          <VerticalTimeline animate>
            {testimonials.map((item, i) => (
              <VerticalTimelineElement
                visible
                key={i}
                date={item.tenure}
                iconStyle={{ background: "#f56539", color: "#fff" }}
                icon={<WorkOutlineIcon />}
                contentStyle={{
                  background: "#212121", // Background color
                  color: "#fff", // Text color
                  boxShadow: "0px 0px 30px #f565394d", // Box shadow
                }}
              >
                <h3 className="vertical-timeline-element-title">
                  {item.company}
                </h3>
                <p>{item.role}</p>
                <p>{item.designation}</p>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Box>
      </Box>
      <Divider />
    </div>
  );
};

export default Testimonials;
